import React from 'react';
import { Helmet } from 'react-helmet';
import { useTitle } from '../TitleContext';

const Coupons = () => {
  const defaultTitle = useTitle();
  return (
    <div>
    <Helmet>
      <title>{`${defaultTitle} - クーポン`}</title>
    </Helmet>
      <h1>クーポンページ</h1>
      <p>ここにクーポン情報を表示します。</p>
    </div>
  );
};

export default Coupons;
