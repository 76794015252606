// src/components/PrivateRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AWS from 'aws-sdk';
import { poolData, identityPoolId } from '../awsConfig';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

const PrivateRoute = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null); // 初期状態をnullに変更
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const idToken = localStorage.getItem('idToken'); // IDトークンを取得
  const email = localStorage.getItem('email')?.toLowerCase(); // 小文字に変換
  const location = useLocation();

  useEffect(() => {
    if (!accessToken || !refreshToken || !idToken || !email) {
      setIsLoggedIn(false);
      return;
    }

    AWS.config.update({
      region: 'ap-northeast-1',
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPoolId,
        Logins: {
          [`cognito-idp.ap-northeast-1.amazonaws.com/${poolData.UserPoolId}`]: idToken,
        },
      }),
    });

    const userPool = new CognitoUserPool(poolData);
    const userData = { Username: email, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.getSession((err, session) => {
      if (err) {
        console.error('Session retrieval failed:', err);
        setIsLoggedIn(false);
      } else {
        cognitoUser.refreshSession(session.getRefreshToken(), (err, session) => {
          if (err) {
            console.error('Token refresh failed:', err);
            setIsLoggedIn(false);
          } else {
            localStorage.setItem('accessToken', session.getAccessToken().getJwtToken());
            localStorage.setItem('idToken', session.getIdToken().getJwtToken());
            setIsLoggedIn(true);
          }
        });
      }
    });
  }, [accessToken, email, refreshToken, idToken]);

  if (isLoggedIn === null) {
    return <div>Loading...</div>; // ローディング状態を表示
  }

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />;
};

export default PrivateRoute;
