import React, { useState } from 'react';
import { poolData } from '../awsConfig';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { Container, TextField, Button, Typography, Box } from '@mui/material';

const ConfirmRegistration = () => {
  const [username, setUsername] = useState('');
  const [confirmCode, setConfirmCode] = useState('');

  const handleConfirmRegistration = () => {
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(confirmCode, true, (err, result) => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
      console.log('call result: ' + result);
      window.location.href = '/login';
    });
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ textAlign: 'center', mt: 5 }}>
        <Typography variant="h4">KitonSite 認証画面</Typography>
        <Typography variant="body1" mt={2}>ユーザー名と認証コードを入力してください。</Typography>
        <Box mt={2}>
          <TextField fullWidth label="Username" value={username} onChange={(e) => setUsername(e.target.value)} margin="normal"/>
        </Box>
        <Box mt={2}>
          <TextField fullWidth label="Confirm Code" value={confirmCode} onChange={(e) => setConfirmCode(e.target.value)} margin="normal"/>
        </Box>
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleConfirmRegistration}>認証</Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ConfirmRegistration;
