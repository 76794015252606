import React from 'react';
import News from '../components/News';
import Blog from '../components/Blog';
import { Helmet } from 'react-helmet';
import { useTitle } from '../TitleContext';

const Home = () => {
  const defaultTitle = useTitle();
  return (
    <div className="content">
      <Helmet>
        <title>{`${defaultTitle} - ホームページ`}</title>
      </Helmet>
      <h1>TOPページ</h1>
      <div className="card">
        <News />
      </div>
      <div className="card">
        <Blog />
      </div>
      <div className="card">おすすめ商品</div>
    </div>
  );
};

export default Home;
