// src/auth/LoginUser.js
import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { poolData, identityPoolId } from '../awsConfig';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import { Container, TextField, Button, Typography, Box } from '@mui/material';

const LoginUser = ({ setUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/account";

  const handleLogin = () => {
    const lowerCaseEmail = email.toLowerCase(); // 小文字に変換
    const authenticationData = {
      Username: lowerCaseEmail,
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: lowerCaseEmail,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const idToken = result.getIdToken().getJwtToken();
        const accessToken = result.getAccessToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken();

        // トークンをローカルストレージに保存
        localStorage.setItem('idToken', idToken);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('email', lowerCaseEmail);

        // AWS SDKの設定
        AWS.config.region = 'ap-northeast-1';
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: identityPoolId,
          Logins: {
            [`cognito-idp.ap-northeast-1.amazonaws.com/${poolData.UserPoolId}`]: idToken,
          },
        });

        AWS.config.credentials.refresh((error) => {
          if (error) {
            console.error('Error refreshing credentials:', error);
          } else {
            console.log('Successfully logged in!');
            setUser({ email: lowerCaseEmail });
            navigate(from, { replace: true });
          }
        });
      },

      onFailure: (err) => {
        console.error("Authentication failed:", err);
        alert(err.message || JSON.stringify(err));
      },
    });
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ textAlign: 'center', mt: 5 }}>
        <Typography variant="h4">KitonSite ログイン画面</Typography>
        <Box mt={2}>
          <TextField fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} margin="normal"/>
        </Box>
        <Box mt={2}>
          <TextField fullWidth label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} margin="normal"/>
        </Box>
        <Box mt={2} display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleLogin} sx={{ mr: 2 }}>ログイン</Button>
          <Button variant="contained" color="secondary" component={Link} to="/signup">サインアップ</Button>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginUser;
