import React, { createContext, useContext } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

const GoogleMapsLoaderContext = createContext();

export const GoogleMapsLoaderProvider = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCMJe_tT7UTig9Jk0l3gYvWcn4Ljo5Y8SY' // ここにAPIキーを入力
  });

  return (
    <GoogleMapsLoaderContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </GoogleMapsLoaderContext.Provider>
  );
};

export const useGoogleMapsLoader = () => useContext(GoogleMapsLoaderContext);
