import React, { useState } from 'react';
import { poolData } from '../awsConfig';
import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Container, TextField, Button, Typography, Box } from '@mui/material';

const SignUp = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignUp = () => {
    const userPool = new CognitoUserPool(poolData);

    const attributeList = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      })
    ];

    userPool.signUp(username, password, attributeList, null, (err, result) => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
      const cognitoUser = result.user;
      console.log('user name is ' + cognitoUser.getUsername());
      window.location.href = '/confirm-registration';
    });
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ textAlign: 'center', mt: 5 }}>
        <Typography variant="h4">KitonSite サインイン画面</Typography>
        <Box mt={2}>
          <TextField fullWidth label="Username" value={username} onChange={(e) => setUsername(e.target.value)} margin="normal" />
        </Box>
        <Box mt={2}>
          <TextField fullWidth label="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} margin="normal" />
        </Box>
        <Box mt={2}>
          <TextField fullWidth label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} margin="normal"/>
        </Box>
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleSignUp}>サインアップ</Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SignUp;
