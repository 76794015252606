import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGoogleMapsLoader } from '../GoogleMapsLoaderContext';

const fallbackStores = [
  {
    id: 1,
    name: 'test',
    address: '愛知県豊橋市西高師町字奥谷46‐20',
    image: 'https://via.placeholder.com/100',
    status: '開店中'
  },
  {
    id: 2,
    name: '豊橋駅',
    address: '〒441-8019 愛知県豊橋市花田町西宿',
    image: 'https://via.placeholder.com/100',
    status: '開店準備中'
  },
  {
    id: 3,
    name: 'みね',
    address: '愛知県豊橋市萱町１４ 八千代ビル 3F',
    image: 'https://via.placeholder.com/100',
    status: '開店準備中'
  }
];

const geocodeAddress = async (address) => {
  const apiKey = 'AIzaSyCMJe_tT7UTig9Jk0l3gYvWcn4Ljo5Y8SY';
  const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
    params: {
      address,
      key: apiKey
    }
  });
  const { results } = response.data;
  if (results.length > 0) {
    const { lat, lng } = results[0].geometry.location;
    return { lat, lng };
  }
  return null;
};

const StoreList = () => {
  const [stores, setStores] = useState([]);
  const navigate = useNavigate();
  const { isLoaded } = useGoogleMapsLoader();

  useEffect(() => {
    const fetchStores = async () => {
      const fallbackStoresWithCoordinates = await Promise.all(fallbackStores.map(async (store) => {
        if (isLoaded) {
          const coordinates = await geocodeAddress(store.address);
          console.log(`Fallback coordinates for ${store.name}:`, coordinates); // デバッグ用ログ
          return { ...store, coordinates };
        }
        return store;
      }));
      setStores(fallbackStoresWithCoordinates);
    };

    if (isLoaded) {
      fetchStores();
    }
  }, [isLoaded]);

  const handleCardClick = (storeName, isDisabled) => {
    if (!isDisabled) {
      console.log(`Navigating to /store-entry/qr-code/${storeName}`); // デバッグ用ログ
      navigate(`/store-entry/qr-code/${storeName}`);
    }
  };

  return (
    <Grid container spacing={2}>
      {stores.map(store => {
        const isDisabled = store.status !== '開店中';
        return (
          <Grid item xs={12} key={store.id}>
            <Card
              sx={{ display: 'flex', alignItems: 'center', opacity: isDisabled ? 0.5 : 1 }}
              onClick={() => handleCardClick(store.name, isDisabled)}
            >
              <CardMedia
                component="img"
                sx={{ width: 100, height: 100 }}
                image={store.image || 'https://via.placeholder.com/100'}
                alt={store.name}
              />
              <CardContent>
                <Typography variant="h6">{store.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {store.address}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {store.status}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default StoreList;
