import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import { Container, Typography, Box, CircularProgress, Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { identityPoolId, poolData } from '../awsConfig';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

const Account = () => {
  const [userInfo, setUserInfo] = useState(null);
  const email = localStorage.getItem('email')?.toLowerCase();
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const idToken = localStorage.getItem('idToken');

  useEffect(() => {
    if (!accessToken || !refreshToken || !idToken || !email) {
      console.error('必要なトークンが見つかりません');
      return;
    }

    AWS.config.update({
      region: 'ap-northeast-1',
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPoolId,
        Logins: {
          [`cognito-idp.ap-northeast-1.amazonaws.com/${poolData.UserPoolId}`]: idToken,
        },
      }),
    });

    const userPool = new CognitoUserPool(poolData);
    const userData = { Username: email, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.getSession((err, session) => {
      if (err) {
        console.error('Session retrieval failed:', err);
      } else {
        cognitoUser.refreshSession(session.getRefreshToken(), (err, session) => {
          if (err) {
            console.error('Token refresh failed:', err);
          } else {
            localStorage.setItem('accessToken', session.getAccessToken().getJwtToken());
            localStorage.setItem('idToken', session.getIdToken().getJwtToken());
            const params = {
              AccessToken: session.getAccessToken().getJwtToken(),
            };

            const cognitoISP = new AWS.CognitoIdentityServiceProvider();
            cognitoISP.getUser(params, (err, data) => {
              if (err) {
                console.error('ユーザー情報の取得に失敗しました:', err);
              } else {
                const emailAttribute = data.UserAttributes.find(attr => attr.Name === 'email');
                setUserInfo({
                  Username: data.Username,
                  Email: emailAttribute ? emailAttribute.Value : ''
                });
              }
            });
          }
        });
      }
    });
  }, [accessToken, email, refreshToken, idToken]);

  if (!userInfo) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ textAlign: 'center', mt: 5 }}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>アカウント情報</Typography>
          <Typography variant="h6">名前: {userInfo.Username}</Typography>
          <Typography variant="h6">メールアドレス: {userInfo.Email}</Typography>
          <Box mt={2}>
            <Button variant="contained" color="secondary" component={Link} to="/logout">
              ログアウト
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default Account;
