import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { poolData } from '../awsConfig';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userPool = new CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.signOut();
    }

    // ローカルストレージからトークンを削除
    localStorage.removeItem('idToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('email');

    // ログインページにリダイレクト
    navigate('/login');
  }, [navigate]);

  return (
    <div>
      <h1>ログアウト中...</h1>
    </div>
  );
};

export default Logout;
