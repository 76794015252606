import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../YSlogo.svg'; // logo.svg をインポート

const Navbar = () => {
  const navigate = useNavigate();

  const isLoggedIn = !!localStorage.getItem('accessToken');

  const handleLogout = () => {
    localStorage.removeItem('idToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate('/login');
  };

  return (
    <AppBar position="fixed">
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <IconButton edge="start" color="inherit" aria-label="account" component={Link} to="/account" sx={{ transform: 'scale(2)' }}>
          <AccountCircle />
        </IconButton>
        <Typography variant="h6" style={{ flexGrow: 1, textAlign: 'center' }}>
          <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
            <img src={logo} alt="ロゴ" style={{ height: '40px' }} /> {/* ここでロゴを表示 */}
          </Link>
        </Typography>
        {isLoggedIn ? (
          <Button color="inherit" onClick={handleLogout}>
            ログアウト
          </Button>
        ) : (
          <Button color="inherit" component={Link} to="/login">
            ログイン
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
