import React from 'react';
import News from '../components/News';

const NewsPage = () => {
  return (
    <div>
      <News />
    </div>
  );
};

export default NewsPage;
