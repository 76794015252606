import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import AWS from 'aws-sdk';
import QRCode from 'qrcode.react';
import { identityPoolId } from '../awsConfig';

const QrCodePage = () => {
  const { storeName } = useParams();
  const [tempKey, setTempKey] = useState('');
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      console.error('Access Tokenが見つかりません');
      return;
    }

    console.log('Access Token:', accessToken); // デバッグ用ログ

    AWS.config.update({
      region: 'ap-northeast-1',
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPoolId,
      }),
    });

    const cognitoISP = new AWS.CognitoIdentityServiceProvider();
    const params = {
      AccessToken: accessToken,
    };

    let isMounted = true;

    const fetchUserData = async () => {
      try {
        const data = await cognitoISP.getUser(params).promise();
        if (isMounted) {
          console.log('User data:', data); // デバッグ用ログ

          const userIdAttribute = data.UserAttributes.find(attr => attr.Name === 'sub');
          setUserInfo({
            UserId: userIdAttribute ? userIdAttribute.Value : ''
          });

          const response = await axios.post('https://2oimvioc09.execute-api.ap-northeast-1.amazonaws.com/prod/setup', {
            username: data.Username,
            store: storeName
          }, {
            headers: {
              Authorization: accessToken
            }
          });

          console.log('API response:', response.data); // デバッグ用ログ
          const responseData = JSON.parse(response.data.body); // ここでレスポンスボディをパース
          setTempKey(responseData.temp_key);
        }
      } catch (error) {
        console.error('ユーザー情報の取得に失敗しました:', error);
      }
    };

    fetchUserData();

    return () => {
      isMounted = false;
    };
  }, [storeName]);

  if (!userInfo || !tempKey) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <h1>QRコード生成中...</h1>
      </div>
    );
  }

  const qrValue = `https://tenpotest.yashubustudioetc.com/confirm-registration?userId=${userInfo.UserId}&tempKey=${tempKey}&storeName=${storeName}`;

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>QRコード</h1>
      <QRCode value={qrValue} />
      <p>{qrValue}</p>
    </div>
  );
};

export default QrCodePage;
