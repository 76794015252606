import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <Box sx={{ textAlign: 'center', mt: 5 }}>
        <Typography variant="h4" component="h1">TOPページ</Typography>
        <Box mt={2}>
            <Button variant="contained" color="primary" component={Link} to="/signup">SignUp</Button>
        </Box>
        <Box mt={2}>
            <Button variant="contained" color="secondary" component={Link} to="/login">Login</Button>
        </Box>
    </Box>
  );
};

export default HomePage;