import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';
//import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useTitle } from '../TitleContext';
import Spacer from '../components/Spacer';
import StoreList from './StoreList';
import { useGoogleMapsLoader } from '../GoogleMapsLoaderContext';

const containerStyle = {
  width: '100%',
  height: '30vh'
};

const center = {
  lat: 34.7691,
  lng: 137.3914
};

const fallbackStores = [
  {
    id: 1,
    name: 'test',
    address: '愛知県豊橋市西高師町字奥谷46‐20',
    coordinates: { lat: 34.7193109127, lng: 137.3820775213 }
  },
  {
    id: 2,
    name: '豊橋駅',
    address: '〒441-8019 愛知県豊橋市花田町西宿',
    coordinates: { lat: 34.7633725205, lng: 137.3826112223 }
  },
  {
    id: 3,
    name: 'みね',
    address: '愛知県豊橋市萱町１４ 八千代ビル 3F',
    coordinates: { lat: 34.7656748385, lng: 137.3854179682 }
  }
];

const StoreEntry = () => {
  const defaultTitle = useTitle();
  const { isLoaded } = useGoogleMapsLoader();

  const [stores, setStores] = useState([]);

  useEffect(() => {
    if (isLoaded) {
      setStores(fallbackStores);
    }
  }, [isLoaded]);

  return (
    <div>
      <Helmet>
        <title>{`${defaultTitle} - 入店管理`}</title>
      </Helmet>
      <Box sx={{ height: 'calc(100vh - 64px - 56px)', overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Paper elevation={3} sx={{ position: 'fixed', top: 64, left: '2vw', right: '2vw', width: '96vw', zIndex: 1 }}>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={14}
              options={{
                disableDefaultUI: true,
                gestureHandling: 'greedy'
              }}>
              {stores.map(store => store.coordinates && (
                <Marker
                  key={store.id}
                  position={store.coordinates}
                  title={store.name}
                />
              ))}
            </GoogleMap>
          ) : (
            <Typography variant="h5" align="center">Loading Map...</Typography>
          )}
        </Paper>
        <Spacer height={20} />
        <Box sx={{ mt: '30vh', width: '100%', height: 'calc(100vh - 30vh - 20px - 64px - 56px)', overflowY: 'auto' }}>
          <StoreList />
        </Box>
      </Box>
    </div>
  );
};

export default StoreEntry;
